//PICK UP
$(function () {
  //
  $(".slider").slick({
    dots: true,
    //fade: true,
    cssEase: "ease-in-out",
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 3500,
    speed: 1200,
    lazyLoad: "progressive",
    swipe: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          arrows: false,
          slidesToShow: 1,
          swipe: true,
        },
      },
    ],
  });
});

//$("._txt").css("display", "none");
$("._close-btn").click(function () {
  var $name = $(this).parent();
  var $headDist = $("header").outerHeight() + 10;
  if ($(this).prev("div").css("display") == "none") {
    $(this).prev("div").slideDown("slow");
  } else {
    $(this).prev("div").slideUp("slow");
  }
  $("html, body").animate({ scrollTop: $name.offset().top - $headDist }, 500);
});

$("._close-btn").click(function () {
  $(this).toggleClass("active");
});

$(document).ready(function () {
  $("#pageTop").hide();
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 700) {
      $("#pageTop").fadeIn("fast");
    } else {
      $("#pageTop").fadeOut("fast");
    }
  });
  $("#pageTop,.logo").click(function () {
    $("body,html").animate(
      {
        scrollTop: 0,
      },
      500
    );
    return false;
  });
});
